.upload-selection-modal {
    font-family: 'Montserrat';
    position: fixed; /* Positioning the modal fixed relative to the viewport */
    top: 40%; /* Centering vertically */
    left: 50%; /* Centering horizontally */
    transform: translate(-50%, -50%); /* Adjusting the position to the exact center */
    width: 525px; /* Width of the modal */
    height: auto; /* Height depends on the content */
    background-color: white; /* Background color of the modal */
    padding: 30px; /* Padding inside the modal */
    border-radius: 10px; /* Rounded corners for the modal */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
    z-index: 1000; /* Ensuring the modal is above other elements */
    display: none; /* Initially hidden */
    transition: all 0.3s ease-in-out; /* Smooth transition for opening/closing */
}

.upload-selection-modal.show {
    display: block; /* Display the modal when the show class is added */
}

.uploadselectionmodal-header {
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    margin: 0 0 15px 0;
}

.uploadselectionmodal-card {
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    background: #ECECF0;
    border-radius: 10px;
    margin: 0 20px 0 0;
    width: 100px;
    height: 50px;
}

.uploadselectionmodal-card:hover {
    cursor: pointer;
    background: #dfdfe4;
}

.button-container {
    display: flex; /* Enables Flexbox, laying out its children in a row */
    justify-content: center; /* Centers the buttons horizontally in the container */
    align-items: center; /* Aligns the buttons vertically in the center */
    gap: 20px; /* Adds space between the buttons */
}


.uploadselectionmodal-card {
    display: flex; /* Enables Flexbox */
    align-items: center; /* Vertically centers the flex items */
    justify-content: center; /* Horizontally centers the flex items */
    gap: 8px; /* Adds space between the icon and text */
    /* Keep the rest of your styles unchanged */
}

.button-content {
    display: flex; /* Use Flexbox inside the span as well */
    align-items: center; /* Ensures the icon and text are aligned vertically */
    justify-content: center; /* Centers the content, though this might be more relevant if you adjust padding or width */
}

.upload-icon {
    font-size: 20px;
    margin: 0 3px 0 0;
}
