.page-container {
  background: #f5f5f9;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure this container is flex to handle the layout */
  flex-direction: column; /* Stack toolbar, content, and audio player */
}

.inner-page-layout {
  display: flex;
  flex-direction: row;
  padding-top: 70px; /* Adjust this value based on your toolbar's height */
  padding-bottom: 90px; /* Adjust this value based on your audio player's height */
  width: 100%; /* Ensure this takes the full width */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.profile-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 900px;
  background: #1a1a1a;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(9, 9, 9, 0.04) 58%,
    rgba(0, 0, 0, 0.1) 98%
  );
  border-radius: 5px;
  align-items: center;
  font-family: "Montserrat";
  padding: 25px;
}

.profile-artist-avatar {
  border-radius: 80px;
  height: 120px;
  width: 120px;
}

.avatar-placeholder {
  color: #2d2d2d;
}

.profile-details {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 20px;
}

.profile-artist-name {
  padding: 0;
  margin: 0 0 4px 0;
  font-size: 26px;
  color: white;
  font-weight: 700;
}

.profile-artist-region {
  padding: 0;
  margin: 0 0 8px 0;
  font-size: 15px;
  color: grey;
  font-weight: 300;
}

.profile-artist-fans {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 300;
}

.profile-follow-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 700;
  background: #f4f4f8;
  outline: none;
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 5px;
  color: black;
}

.profile-follow-btn:hover {
  cursor: pointer;
  background: #e1e1e5;
}

.profile-filter-btn-container {
  margin: 40px 0 25px 0;
}

.profile-filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s ease;
  color: black;
}

.profile-filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.profile-filter-btn.selected {
  background-color: #dddde2; /* Adjust the color as needed */
}

.profile-load-more-btn {
  background: #ebebee;
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  padding: 4px 8px;
  width: 900px;
}

.profile-load-more-btn:hover {
  background: #d4d4d7;
}

@media only screen and (max-width: 420px) {
  .profile-banner {
    width: 365px;
    padding: 20px;
  }

  .profile-follow-btn {
    font-size: 11px;
  }

  .profile-artist-avatar {
    height: 100px;
    width: 100px;
  }

  .profile-artist-name {
    font-size: 20px;
  }

  .profile-artist-region {
    font-size: 13px;
  }

  .profile-artist-fans {
    font-size: 12px;
  }

  .profile-load-more-btn {
    width: 365px;
  }
}
