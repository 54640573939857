.promote-music-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.promote-music-container {
  font-family: "Montserrat";
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 775px;
  height: auto;
  position: relative;
  bottom: 10%;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.promo-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 0 0;
}

.promo-campaign-summary-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 30px 0 70px 0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: -1px 3px 13px 0px rgba(0, 0, 0, 0.094);
}

.promo-header-text {
  font-weight: 700;
  font-size: 27px;
  text-align: center;
  margin: 0;
}

.modal-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 0;
}

.promo-type-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-type-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c2c2c7;
  background: none;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 20px 0 20px;
  transition: all 0.3s ease;
}

.promo-type-card:hover {
  border: 2px solid #0091ff;
}

.promo-type-icon {
  font-size: 80px;
  color: #c2c2c7;
}

.promo-type-card.selected {
  border: 2px solid #0091ff;
}

.promo-type-icon.selected-icon {
  color: #0091ff;
}

.promo-text.selected-text {
  color: black;
}

.promo-text {
  font-size: 20px;
  color: #c2c2c7;
  font-weight: 600;
}

.promote-modal-next-btn {
  border: none;
  background-color: #0091ff;
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  width: 500px;
  margin: 50px auto 0 auto;
}

.promote-modal-next-btn:hover {
  background-color: #0488ec;
}

.promo-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 0 0;
}

.promo-next-btn {
  background-color: #0091ff;
  border: none;
  padding: 8px;
  border-radius: 6px;
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.promo-next-btn:hover {
  background-color: #0488ec;
}

.promo-back-btn {
  background-color: #dddde2;
  border: none;
  padding: 8px;
  border-radius: 6px;
  color: black;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.promo-back-btn:hover {
  background-color: #cbcbd2;
}

.promo-finish-btn {
  background-color: #02b10d;
  border: none;
  padding: 8px;
  border-radius: 6px;
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.promo-finish-btn:hover {
  background-color: #059e0f;
}

.promo-detail-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.promo-detail-group {
  display: flex;
  flex-direction: column;
}

.promo-detail {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
}

.promo-detail-header {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.promo-detail-description {
  font-size: 9px;
  color: #808083;
  font-weight: 500;
  width: 200px;
  margin: 5px 0 0 0;
}

.promo-setting-text {
  font-weight: 600;
  color: #656569;
  font-size: 14px;
  margin: 0;
}

.promo-detail-custom {
  font-size: 12px;
  font-weight: 700;
  color: #0a951a;
  margin: 10px 0 0 0;
}

.credit-cost-box {
  background-color: none;
  border: 1px solid #707074;
  margin: 0 0 0 7px;
  padding: 1px 15px 1px 15px;
  border-radius: 4px;
  font-size: 12px;
}

.full-width-line {
  border: none;
  border-top: 1px solid #808083;
  width: 100%;
  margin: 25px 0 0 0;
  opacity: 0.2;
}

.promo-slider-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.promo-slider {
  flex: 1;
  margin-right: 10px;
  -webkit-appearance: none; 
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s;
}

.promo-slider::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none;
  width: 16px;
  height: 16px;
  background: #0091ff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.promo-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #0091ff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.promo-slider:hover {
  background: #ccc;
}

.promo-setting-text {
  font-weight: 600;
  color: #656569;
  font-size: 14px;
  margin: 0;
}

.promo-track-select {
  background: none;
  border: 1px solid #c2c2c7;
  padding: 3px 6px 3px 6px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  font-family: "Montserrat";
  font-weight: 500;
}

.custom-date-picker-input {
  background: none;
  border: 1px solid #c2c2c7;
  padding: 3px 6px 3px 6px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 500;
}

.promo-audience-btn {
  background: none;
  border: 1px solid #c2c2c7;
  padding: 3px 6px 3px 6px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 500;
}

.artist-audience {
  margin: 0 7px 0 0;
}

.promo-review-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.promo-review-text {
  color: #808083;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 5px 0;
}

.promo-cost-overview-container {
  margin: 10px 0 0 0;
}

.promo-cost-overview {
  margin: 5px 0 5px 0;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.promo-cost-spend {
  background-color: #fff0f0;
  color: #b10101;
  padding: 2px 6px 2px 6px;
  border-radius: 10px;
  margin: 0 0 0 5px;
  font-weight: 600;
}

.promo-cost-remaining {
  background-color: #f0fff2;
  color: #01b13c;
  padding: 2px 6px 2px 6px;
  border-radius: 10px;
  margin: 0 0 0 5px;
  font-weight: 600;
}
