.inner-page-layout {
  display: flex;
  flex-direction: row;
  padding-top: 70px; /* Adjust this value based on your toolbar's height */
  padding-bottom: 90px; /* Adjust this value based on your audio player's height */
  width: 100%; /* Ensure this takes the full width */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.search-query-text {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.search-page-divider {
  margin: 20px 0 15px 0;
  width: 900px;
  color: #979797;
  opacity: 0.5;
}

.search-input-search-page-container {
  display: none;
}



@media only screen and (max-width: 420px) {
  .search-page-divider {
    width: 365px;
  }
  .search-input-search-page-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
    width: 360px;
  }

  .search-input-search-page {
    font-family: "Montserrat";
    border-radius: 3px;
    padding: 8px 50px 8px 10px; 
    border: 1px solid #E5E5EA;
    background: #FAFAFC;
    outline: none;
    margin: 0 0 15px 0;
  }

  .search-page-search-btn {
    font-family: "Montserrat";
    color: white;
    border-radius: 3px;
    background: #3f007c;
    border: none;
    font-weight: 600;
    height: 30px;
    cursor: pointer;
  }

  .search-page-search-btn:hover {
    background: #320064;

  }


}
