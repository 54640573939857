body {
  background: #f5f5f9;
}

.page-container {
  background: #f5f5f9;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure this container is flex to handle the layout */
  flex-direction: column; /* Stack toolbar, content, and audio player */
}

.inner-page-layout {
  display: flex;
  flex-direction: row;
  padding-top: 70px; /* Adjust this value based on your toolbar's height */
  padding-bottom: 90px; /* Adjust this value based on your audio player's height */
  width: 100%; /* Ensure this takes the full width */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.inner-page-content {
  display: flex;
  flex-direction: column;
  margin: 40px 0 60px 220px;
  overflow-y: auto;
  flex-grow: 1; /* Let it take up available space */
}

.header-titles {
  font-family: "Montserrat";
  font-size: 27px;
  font-weight: 700;
  padding: 0 0 10px 0;
}

.filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  color: black;
}

.filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.filter-btn.selected {
  background-color: #dddde2; /* Adjust the color as needed */
}

@media only screen and (max-width: 420px) {
  .inner-page-content {
    margin-left: 20px;
    margin-top: 25px;
  }

  .header-titles {
    font-size: 24px;
  }

}
