.notification-item {
    display: flex;
    flex-direction: column;
}

.notification-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding: 10px 3px 5px 3px;
}

.notification-icon  {
    margin: 0 0 0 0;
    font-size: 20px;
}

.trophy-icon {
    color: #F2B40B;
    margin: 0 8px 0 0;
}

.newtracks-icon {
    color: #4BC32D;
    margin: 0 8px 0 0;
}

.newfan-icon {
    color: #4BC32D;
    margin: 0 8px 0 0;
}

.like-icon {
    color: red;
    margin: 0 8px 0 0; 
}

.chart-icon {
    color: #3F007C;
    margin: 0 8px 0 0;
}

.new-icon {
    color: #4BC32D;
    margin: 0 8px 0 0;
}

.notification-message {
    color: black;
    font-size: 13px;
    margin: 0;
    font-weight: 500;
}

.notification-divider {
    color: #DDDDE2;
    opacity: 0.2;
    width: 100%;
    margin: 0;
}