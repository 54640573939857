.audio-track-container {
  background: white;
  width: 900px;
  height: auto;
  padding: 8px;
  margin: 0 0 8px 0;
  border-radius: 8px;
  touch-action: manipulation;
}

.audio-track-container:hover {
  box-shadow: 0 5px 11px 0 rgba(206, 206, 207, 0.62);
}

.audio-track-content {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  align-items: center;
  padding: 2px;
}

.audio-track-chart-position {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
  color: #d7d7de;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 0 10px 0 0;
  font-weight: 700;
}

.cover-art-container {
   position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; 
  min-width: 60px; 
}

.audio-track-cover-art {
  width: 100%;
  height: 100%;
}

.play-pause-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cover-art-container:hover .play-pause-icon {
  opacity: 1;
}

.audio-track-details {
   display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
  flex-grow: 1; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
}

.audio-track-details-spacing {
  margin: 3px 0 3px 0;
}

.audio-track-title {
  font-weight: 600;
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0;
  word-wrap: break-word; 
  white-space: normal;
}

.audio-track-artist {
  font-weight: 500;
  font-size: 11px;
  color: #808083;
  padding: 0;
}

.user-profile-link {
  text-decoration: none;
  color: #808083;
}

.user-profile-link:visited {
  color: #808083;
}

.user-profile-link:hover {
  color: #616164;
  text-decoration: underline;
}

.audio-track-genre {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  background: #dddde2;
  font-size: 8px;
  font-weight: 700;
  color: black;
  width: auto;
  height: 10px;
  border-radius: 15px;
  padding: 8px;
  border: none;
}

.audio-track-actions-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 auto;
}

.credits-section {
  margin: 10px 0 0 0;
}

.audio-track-credits-btn {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  background: #dddde2;
  font-size: 7px;
  font-weight: 600;
  color: black;
  width: auto;
  height: 20px;
  border-radius: 15px;
  padding: 7px;
  border: none;
  margin: 0 20px 0 0;
}

.audio-track-credits-btn:hover {
  cursor: pointer;
}

.like-audio-track-btn {
  margin: 0 20px 0 auto;
  font-size: 23px;
  border: none;
  background: none;
  transition: all 0.3s ease;
  color: black;
}

.like-audio-track-btn:hover {
  cursor: pointer;
  color: red;
}

.audio-track-credits {
  font-family: "Montserrat";
  display: flex;
  flex-direction: row;
  font-size: 9px;
  background: none;
  margin: 4px 0 4px 0;
}

.credit-title {
  font-weight: 700;
  color: black;
  margin: 0 5px 0 0;
}

.credit-info {
  font-weight: 600;
  color: #808083;
}

.audio-track-actions-group {
  position: relative;
  font-family: 'Montserrat';
}

.audio-track-actions {
  font-size: 20px;
  cursor: pointer;
  color: #C2C2C7;
}

.track-actions-modal {
  position: absolute;
  top: 30px; 
  right: 0;
  background-color: white;
  padding: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
}

.add-to-playlist-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  background-color: white;
  color: black;
  border: none;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.add-to-playlist-btn:hover {
  background-color: #f0f0f0;
}

.playlist-plus-btn {
  margin: 0 5px 0 0;
  font-size: 18px;
}

.add-to-playlist-dropdown {
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.playlist-item {
  padding: 5px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 13px;
}

.playlist-item:hover {
  background-color: #f0f0f0;
  border-radius: 3px;
}

@media only screen and (max-width: 420px) {
  .audio-track-container {
    width: 365px;
  }

  .audio-track-credits-btn {
    display: none;
    font-size: 6px;
    padding: 5px;
    height: 15px;
  }
}
