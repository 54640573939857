body {
  background-color: #f5f5f9;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  padding: 70px 0 0 0;
}

.login-page-logo {
  height: 70px;
  margin: 40px auto 20px auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  padding: 25px 40px 35px 40px;
  border-radius: 14px;
  box-shadow: 7px 7px 18px 1px rgba(0, 0, 0, 0.056);
  position: relative;
}

.login-logo-link {
  margin: 0 auto 0 auto;
}

.login-logo {
  height: 40px;
  width: 200px;
  margin: 0 auto 0 auto;
}

.login-header-text {
  font-size: 20px;
  margin: 20px 0 30px 0;
  font-weight: 400;
  text-align: center;
  font-weight: 600;
}

.login-form-label {
  font-weight: 500;
  font-size: 13px;
}

.login-form-input {
  margin: 7px 0 10px 0;
  background-color: rgb(252, 252, 253);
  border: 1px solid rgb(232, 234, 237);
  border-radius: 4px;
  color: #3d3d40;
  width: auto;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
}

.login-form-input::placeholder {
  color: #6f6f76;
}

.forgot-password-group {
  display: flex;
  text-align: left;
}

.forgot-password {
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  color: #626264;
  margin-right: 145px;
}

.forgot-password:hover {
  cursor: pointer;
  text-decoration: underline;
}

.login-form-btn {
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  font-family: "Montserrat";
  padding: 8px 25px 8px 25px;
  background-color: #0aaa1d;
  font-weight: 600;
  cursor: pointer;
  width: 350px;
  margin: 20px 0 0 0;
}

.login-form-btn:hover {
  background-color: #09971a;
}

.login-form-error-message {
  padding-bottom: 15px;
  color: red;
  font-size: 12px;
  width: 250px;
}

.rememberMeCheckBox {
  height: 15px !important;
  width: 15px;
  margin-left: 0;
}

.rememberMeContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.rememberMeLabel {
  font-weight: 500;
  font-size: 13px;
}

.loginFormPasswordBtn {
  position: absolute;
  right: 20px;
  top: 19px;
  cursor: pointer;
  color: #c4c4ca;
  width: 20px;
}

.loginFormPasswordContainer {
  position: relative;
}

.forgot-password-link {
  font-size: 12px;
  color: #acacb0;
  text-decoration: none;
  margin: 0 0 0 auto;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

/* Override the background color for autofilled inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important; /* Change text color if needed */
}

/* Optional: Change the background color */
input:-webkit-autofill {
  background-color: white !important;
}

@media screen and (max-width: 500px) {
  .login-form {
    width: 355px;
  }

  .login-form-input {
    width: 100%;
  }

  .loginFormPasswordBtn {
    right: 2px;
  }

  .login-form-btn {
    width: auto;
  }
}
