.terms-container {
    text-align: center;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0e0e0e;
}

.augio-logo-terms-page {
    height: 63px;
    width: 261px;
    margin: 30px 0 0 0;
}

.inner-text {
    margin: 0 30px 0 30px;
    padding-bottom: 30px;
    color: #DDDDE2;
}

.terms-header {
   font-size: 40px;
   margin: 50px 0 50px 0;
   text-align: left;
   
}

.terms-subheader {
text-align: left;
font-weight: 700;
font-size: 25px;
}

.terms-subtext {
    text-align: left;
}

.copyrightinfringement-link {
    color: #0091FF;
    font-weight: 600;
}

.member-terms-container {
    text-align: center;
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0e0e0e;
  }
  
  .member-terms-header {
      font-size: 40px;
      margin: 50px 0 50px 0;
      text-align: left;
      font-weight: 600;
      color: #DDDDE2;
  }
  
  .member-terms-text {
      margin: 0 30px 0 30px;
      padding-bottom: 30px;
      text-align: left;
      color: #DDDDE2;
  }
  
  .member-terms-subheader, .member-terms-inner-text {
      font-weight: 600;
  }
  
  .terms-service-link {
      color: #0091FF;
      font-weight: 600;
  }
  

@media only screen and (max-width: 500px) {
    .terms-header {
        font-size: 30px;
     }
}
