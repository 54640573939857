.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}

.playlist-details-box {
  font-family: "Montserrat";
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1300;
  transition: all 0.3s ease-in-out;
  width: 350px;
}

.playlist-header {
  margin: 0 0 5px 0;
  text-align: center;
  font-weight: 600;
}

.playlist-name-input {
  margin: 7px 0 20px 0;
  background-color: rgb(252, 252, 253);
  border: 1px solid rgb(232, 234, 237);
  border-radius: 4px;
  color: #3d3d40;
  width: 100%;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
}

.save-playlist-btn {
  font-family: "Montserrat";
  font-weight: 600;
  padding: 10px 20px;
  background-color: #08ad01;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.delete-playlist-btn {
  font-family: "Montserrat";
  font-weight: 600;
  padding: 10px 20px;
  background-color: #ad0101;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin: 10px 0 0 0;
}

.playlist-message {
  font-family: "Montserrat";
}

.sidenavigation-playlist {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.playlist-name {
  flex-grow: 1;
}

.playlist-elipsis {
  display: none;
  cursor: pointer;
  margin-left: auto;
}

.sidenavigation-playlist:hover .playlist-elipsis {
  display: block;
}

.playlist-actions {
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.playlist-edit,
.playlist-delete {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  background: none;
  color: black;
  border: none;
  cursor: pointer;
}

.playlist-edit:hover,
.playlist-delete:hover {
  background: #f0f0f0;
}

.cancel-playlist-btn {
  background: #c2c2c7;
  font-family: "Montserrat";
  font-weight: 600;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin: 10px 0 0 0;
}
