.audioplayer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: "Montserrat";
  border-top: 1px solid #e7e7ef;
  z-index: 1000;
}

.audioplayer-content {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  align-items: center;
  justify-content: space-between;
}

.audioplayer-song-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
  width: 15%;
}

.audioplayer-song-details {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
}

.audioplayer-artist {
  color: #808080;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.audioplayer-song {
  color: black;
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

.audioplayer-song-cover {
  height: 70px;
  width: 70px;
  border-radius: 5px;
}

.audioplayer-controls-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
}

.audioplayer-controls {
  display: flex;
  gap: 10px; /* Adjust as needed */
  align-items: center;
}

.audioplayer-progress-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.audioplayer-progress-bar {
  background: #c2c2c7;
  width: 450px;
  height: 10px;
  border-radius: 10px;
  margin: 0 15px 0 15px;
  position: relative;
}

.audioplayer-progress-fill {
  background: black; /* Or any color you prefer */
  width: 0%; /* Initially hidden */
  height: 100%;
  border-radius: 10px; /* Match parent's border-radius */
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.2s ease; /* Add this line */
}

.audioplayer-time {
  min-width: 40px;
  padding: 0 5px;
  font-weight: 600;
}

.audio-control-play {
  width: 45px;
}

.audio-control-pause {
  width: 45px;
}

.audio-control-shuffle {
  font-size: 20px;
  color: #a0a0a5;
}

.audio-control-repeat {
  font-size: 20px;
  color: #a0a0a5;
}

.audio-control-shuffle.active,
.audio-control-repeat.active {
  color: black;
}

.audio-control {
  width: 18px;
  margin: 0 10px 0 10px;
}

.audioplayer-control-play,
.audio-control-pause,
.audio-control-shuffle,
.audio-control-repeat,
.audio-control:hover {
  cursor: pointer;
}

.volume-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.volume-icon {
  margin: 0 5px 0 0;
  font-size: 25px;
}

.volume-slider {
  -webkit-appearance: none; /* Override default appearance for WebKit browsers */
  appearance: none; /* Standard property for other browsers */
  width: 100%; /* Full width to fit container */
  height: 10px; /* Set the height of the slider track */
  background: #d3d3d3; /* Light grey color for the slider track */
  outline: none; /* Remove focus outline */
  border-radius: 10px; /* Rounded edges for the slider */
  position: relative; /* Needed for pseudo-element positioning */
  overflow: hidden; /* Keeps pseudo-elements inside the slider bounds */
}

.volume-slider::-webkit-slider-thumb,
.volume-slider::-moz-range-thumb,
.volume-slider::-ms-thumb,
.volume-slider::before {
  /* Using ::before as pseudo-element for filled track */
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #a9a9a9;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.volume-slider::-webkit-slider-runnable-track,
.volume-slider::-moz-range-track,
.volume-slider::-ms-track {
  background: transparent; /* Make default track transparent */
  border-radius: 10px;
}

.volume-slider::before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #a9a9a9; /* Darker grey for filled effect */
  border-radius: 10px;
  left: 0;
  top: 0;
  width: 0%; /* Start with no fill */
}

.volume-slider::before {
  width: var(--fill-percentage);
}

@media only screen and (max-width: 420px) {
  .volume-container {
    display: none;
  }

  .audioplayer-controls {
    margin-right: 10px;
  }

  .audioplayer-progress-slider {
    display: none;
  }

  .audio-control-repeat,
  .audio-control-shuffle {
    display: none;
  }

  .audioplayer-artist {
    font-size: 10px;
  }

  .audioplayer-song {
    font-size: 11px;
    width: 200px;
  }

  .audio-control {
    font-size: 35px;
  }

  .audioplayer-song-cover {
    width: 60px;
    height: 60px;
  }
}
