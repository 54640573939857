.region-page-container {
  font-family: "Montserrat";
}

.region-banner {
  background: #1a1a1a;
  width: 900px;
  height: 200px;
  border-radius: 7px;
  padding: 1px 20px 5px 20px;
}

.region-details-spacing {
  margin: 15px 0 5px 0;
}

.region-title {
  color: white;
  font-size: 22px;
}

.region-name {
  color: white;
  font-size: 22px;
  font-weight: 600;
}

.region-fans {
  color: #dddde2;
  font-weight: 400;
  font-size: 14px;
}

.region-content-container {
  margin: 40px 0 0 0;
}

.region-favorite-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adjust the gap between the icon and the text */
  font-family: "Montserrat";
  font-weight: 600;
  padding: 5px 8px;
  border: none;
  border-radius: 3px;
  background: #dddde2;
  margin: 75px 0 0 0;
  color: black;
}

.region-favorite-btn:hover {
  cursor: pointer;
  background: #bcbcc1;
}

.filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s ease;
  color: black;
}

.filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.genre-filter-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adjust the gap between the icon and the text */
  font-family: "Montserrat";
  font-weight: 600;
  padding: 5px 8px;
  border: none;
  border-radius: 3px;
  background: #dddde2;
  margin: 35px 0 30px 0;
  color: black;
}

.region-genre-filters {
  width: 900px;
  margin: 10px 0 10px 0;
}

.region-genre-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 2px 6px 2px 6px;
  margin: 3px 5px 3px 0;
  font-size: 10px;
  transition: all 0.3s ease;
  color: black;
}

.region-genre-btn-selected {
  background: #dddde2;
}

.region-genre-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.region-tracks-container {
  width: 920px;
  height: 600px;
  overflow-y: auto;
  box-sizing: border-box;
}

.region-tracks-container::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.region-tracks-container::-webkit-scrollbar-thumb {
  background: #dddde2;
  border-radius: 10px;
  height: 70px;
}

@media only screen and (max-width: 420px) {
  .region-banner {
    width: 365px;
    height: 160px;
  }

  .region-favorite-btn {
    margin-top: 40px;
  }

  .region-genre-filters {
    width: 360px;
    margin-bottom: 30px;
  }

  .region-tracks-container {
    width: 370px;
  }
}
