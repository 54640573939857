.progress-spinner-container {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px; 
    height: 100px; 
}

.progress-spinner {
    border: 4px solid rgba(0,0,0,.1);
    border-radius: 50%;
    border-top: 4px solid white; 
    width: 20px; 
    height: 20px; 
    animation: spin 2s linear infinite;
}

.progress-spinner-percentage {
    position: absolute;
    font-size: 13px; 
    color: black; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
