html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/*animations for whole new world text*/

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-offscreen {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-in {
  animation: slideFromLeft 1s forwards;
}

/*animations for introducing and augio logo and subtext*/
.fade-up-init {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s, transform 2s;
}

.fade-up-final {
  opacity: 1;
  transform: translateY(0);
}

/*animations for differentiators*/
.artist-text-init,
.fan-text-init {
  opacity: 0;
  visibility: hidden; /* This ensures the element is not visible*/
}

.artist-text-init {
  transform: translateX(-100%);
}

.fan-text-init {
  transform: translateX(100%);
}

@keyframes slideInArtist {
  from {
    transform: translateX(-100%);
    opacity: 0;
    visibility: visible;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slideInFan {
  from {
    transform: translateX(100%);
    opacity: 0;
    visibility: visible;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.artist-text-final {
  animation: slideInArtist 0.6s forwards;
}

.fan-text-final {
  animation: slideInFan 0.6s forwards;
}

.content-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease-in, visibility 0s linear 1s; /* Start transition after video begins to fade */
}

/*main content animation*/
.main-content-section {
  opacity: 0;
  transform: translateY(150px); /* Initial position is 50px below */
  transition: opacity 2s, transform 1s; /* Adjust the timing if needed */
}

.main-content-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.landing-page-container {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  /*background: #131313;*/
  /*background: #111111;*/
  background: #0e0e0e;
  margin: 0;
  font-family: "Montserrat";
  scroll-behavior: smooth;
  padding-bottom: 120px;
  transition: opacity 0.5s ease-in;
}

.content-visible {
  opacity: 1;
  visibility: visible;
}

.video-container {
  position: absolute; /* Position absolutely to overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 2s ease-out;
  opacity: 1; /* Start fully visible */
  z-index: 2; /* Ensure it's above the content */
}

.video-container.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out; /* Quicker fade-out for a smoother transition */
  height: 0;
  overflow: hidden;
}

.menu-links {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 10px 10px;
  align-items: center;
}

.mobile-app-link-btn {
  font-family: "Montserrat";
  background: linear-gradient(
    45deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 4%,
    hsl(275deg 81% 47%) 8%,
    hsl(275deg 81% 47%) 13%,
    hsl(275deg 81% 47%) 18%,
    hsl(275deg 81% 47%) 23%,
    hsl(275deg 81% 47%) 28%,
    hsl(275deg 81% 47%) 34%,
    hsl(286deg 100% 41%) 39%,
    hsl(302deg 100% 38%) 46%,
    hsl(316deg 100% 42%) 52%,
    hsl(325deg 100% 46%) 59%,
    hsl(332deg 100% 48%) 67%,
    hsl(339deg 100% 48%) 75%,
    hsl(344deg 100% 49%) 85%,
    hsl(352deg 91% 51%) 100%
  );
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 8px 15px 8px 15px;
  font-weight: 700;
  cursor: pointer;
  width: auto;
  transition: all 0.3s ease;
}

.menu-link-spacing {
  margin: 0 5px 0 5px;
}

.anchor-text {
  color: #c2c2c7;
}

.anchor-text:hover {
  color: #8a01db;
  cursor: pointer;
  transition: all 0.3s;
}

.augio-tranparent-g {
  height: 50px;
  width: 45px;
  margin: 5px;
}

.top-page-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-image-text-container {
  margin: 30px auto 0 auto;
  display: flex;
  position: relative; /* Uncommented */
  flex-direction: column;
  align-items: center; /* horizontally center */
  justify-content: center; /* vertically center */
  /*height: 700px;          /* matching the image height */
  /*width: 800px;         /* matching the image width */
}

.augio-landing-image {
  height: 600px;
  width: 960px;
  position: absolute; /* Uncommented */
  margin: 200px 0 0 0;
  z-index: 0; /* Keep image below the text */
}

.custom-font-group {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  z-index: 1; /* Changed from -1 to 1 */
}

.custom-header-font {
  font-family: "Akira Expanded", sans-serif;
  color: white;
  font-size: 40px;
  letter-spacing: 10px;
}

.text-over-image-01 {
  margin-left: -200px;
}

.text-over-image-02 {
  margin-left: -110px;
}

.text-over-image-03 {
  margin-left: -150px;
}

.text-over-image-04 {
  margin-left: -90px;
}

.intro-text {
  margin: 300px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-large-text {
  color: #dedede;
  font-size: 35px;
  text-align: center;
  letter-spacing: 15px;
  padding: 0 0 0 22px;
}

.landing-logo {
  height: 90px;
  margin: 35px auto 40px auto;
}

.introducing-quote-text {
  color: white;
  font-style: italic;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0 auto 80px auto;
  text-align: center;
  width: 570px;
}

.gradient-header-text {
  font-family: "Akira Expanded", sans-serif;
  font-size: 60;
  text-align: center;
  width: 800px;
  margin: 0 auto 0 auto;
  background-image: linear-gradient(
    10deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 10%,
    hsl(275deg 81% 47%) 20%,
    hsl(275deg 81% 47%) 31%,
    hsl(275deg 81% 47%) 42%,
    hsl(292deg 100% 39%) 53%,
    hsl(316deg 100% 42%) 65%,
    hsl(330deg 100% 47%) 77%,
    hsl(340deg 100% 49%) 89%,
    hsl(352deg 91% 51%) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/*border fade as scroll */
@keyframes borderColorFade {
  0% {
    border-image-source: linear-gradient(to right, #c900fc, #f40f2c);
  }
  100% {
    border-image-source: linear-gradient(to right, #f40f2c, #c900fc);
  }
}

.augio-statements-container {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 12px;
  padding: 10px;
  justify-content: center;
  margin: 90px 0 50px 0;
}

.statement-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
}

.statement-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 480px;
  height: 280px;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 30px 5px 0;
  padding: 20px;
  border: 2px solid;
  border-image-slice: 1;
  background: linear-gradient(to right, #aa01d4, #f40f2c);
  transition: opacity 1s ease-in-out, border-image-source 0.5s ease;
  border-radius: 8px;
  opacity: 0;
}

.statement-container.visible {
  opacity: 1;
}

.statement-header {
  color: black;
  margin: 0 15px 2px 0;
  font-family: "Akira Expanded", sans-serif;
  font-size: 22px;
}

.statement-bigtext {
  color: #f40f2c;
  color: white;
  margin: 2px 0 2px 0;
  font-family: "Akira Expanded", sans-serif;
  font-size: 20px;
}

.statement-subtext {
  color: #dadae0;
  margin: 2px 0 0 0;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
}

.link-icon {
  font-size: 24px;
  color: white;
  cursor: pointer;
  transform: rotate(135deg);
  margin: 0;
}

.mobile-chain-link {
  display: none;
}

.border-blue {
  border: 5px solid blue;
  height: 2000px;
}

.border-red {
  border: 5px solid red;
  height: 2000px;
}

.why-join-section {
  display: flex;
  width: 100%;
  margin: 50px auto 50px auto;
  justify-content: center;
}

.artists-why-join {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.fans-why-join {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.why-join-artists {
  font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  color: #8a00dc;
}

.why-join-fans {
  font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  color: #e90d3b;
}

.why-join-title-artists {
  font-family: "Akira Expanded", sans-serif;
  color: #8a00dc;
  font-size: 35px;
  margin: 15px 0 10px 0;
}

.why-join-title-fans {
  font-family: "Akira Expanded", sans-serif;
  color: #f95031;
  font-size: 35px;
  margin: 15px 0 10px 0;
}

.why-join-description {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  width: 530px;
  height: 150px;
  font-size: 17px;
  text-align: center;
  background-color: #1d1c1c;
  padding: 20px;
  border-radius: 10px;
  font-style: italic;
  margin: 0 40px 0 40px;
  color: #dddde2;
}

.description-artists {
  color: #dddde2;
}

.description-fans {
  color: #dddde2;
}

.in-view {
  transform: translateX(0);
  opacity: 1;
}

.subtext {
  font-size: 22px;
  font-weight: 300;
  color: #808083;
  text-align: center;
  width: 700px;
  margin: 30px auto 70px auto;
}

.explore-anchor-container {
  border: 1px solid #9a10bb;
  padding: 1px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin: 30px auto 70px auto;
  cursor: pointer;
  text-decoration: none;
}

.explore-anchor {
  color: #c2c2c7;
  padding: 10px;
  text-decoration: none;
}

.landing-down-arrow {
  font-size: 25px;
  color: #c2c2c7;
  margin-top: 6px;
}

.divider {
  color: #f1f1f1;
  background-color: #f1f1f1;
  width: 100%;
  height: 0.5px;
  opacity: 0.2;
}

/* Animations for benefits */
.benefits-text-init {
  opacity: 0;
  visibility: hidden; /* This ensures the element is not visible */
  transform: translateX(-100%);
}

@keyframes slideInBenefits {
  from {
    transform: translateX(-100%);
    opacity: 0;
    visibility: visible;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.benefits-text-final {
  animation: slideInBenefits 0.6s forwards;
}

.augio-benefits {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 110px 0 50px 0;
}

.benefits-title {
  font-size: 25px;
  font-weight: 700;
}

.artist-benefits-header {
  background: #c900fc;
  padding: 10px;
  text-align: center;
}

.fans-benefits-header {
  background: #e90d3b;
  padding: 10px;
  text-align: center;
}

.artist-benefits {
  display: flex;
  flex-direction: column;
  background-color: rgba(187, 11, 231, 0.062);
  border: 1px solid #c900fc;
  width: 350px;
  margin: 0 20px 0 0;
}

.fan-benefits {
  display: flex;
  flex-direction: column;
  background-color: #1f1214;
  border: 1px solid #e90b3c;
  width: 350px;
  margin: 0 0 0 20px;
}

.benefits-list {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.benefit-item-text {
  color: #eae7e7;
  list-style-type: none;
  font-weight: 600;
  margin: 25px 0 25px 0;
}

.differentiators-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 35px 0 40px 0;
}

.differentiator-comparison {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 0 0;
}

.vertical-divider {
  border-left: 1px solid grey; /* this creates the vertical line */
  height: 270px; /* adjust this to control the height of the vertical line */
  width: 0px;
  margin: 40px 40px 0 40px;
}

.differentiators-text {
  font-family: "Montserrat";
  font-size: 16px;
}

.differentiator-artist {
  color: #8a0adc;
  font-weight: 600;
  font-size: 20px;
  text-decoration: underline;
  text-align: center;
}

.differentiator-musicfan {
  color: #f95031;
  font-weight: 600;
  font-size: 20px;
  text-decoration: underline;
  text-align: center;
}

.differentiator-subtext {
  color: white;
  border: 1px solid #f2f2f242;
  border-radius: 8px;
  text-align: center;
  width: 330px;
  padding: 5px;
}

.subheader-text {
  color: white;
  font-family: "Akira Expanded", sans-serif;
  font-size: 34px;
  text-align: center;
}

.custom-font-heading {
  font-family: "Akira Expanded", sans-serif;
  color: #8428ca;
  font-size: 20px;
  text-align: center;
}

.features-section {
  margin: 140px 0 50px 0;
  display: none;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.features-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 200px;
}

.feature-content {
  min-width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
}

.feature-text-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.feature-title,
.feature-quote,
.feature-description {
  text-align: center;
  justify-content: center;
  color: white;
}

.feature-title {
  font-weight: 800;
  font-size: 30px;
  margin: 0 0 15px 0;
  font-style: italic;
}

.feature-quote {
  font-weight: 300;
  font-size: 13px;
  width: 500px;
  margin: 0 0 15px 0;
}

.feature-description {
  font-weight: 400;
  font-size: 19px;
  width: 500px;
  margin: 0;
}

.feature-image-screenshot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 250px;
  background-color: #151515;
  border-radius: 20px;
}

.feature-image-screenshot {
  width: 300px;
  height: 180px;
  margin: 0 auto;
  object-fit: contain;
}

.mobile-app-preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
  text-align: center;
}

.ios-coming-soon {
  font-family: "Montserrat";
  font-size: 12px;
  margin: 0;
  color: #808083;
  font-weight: 500;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.carousel-content.fade {
  opacity: 0;
}

.ios-app-image {
  width: 320px;
  height: 555px;
  object-fit: contain;
}

.ios-feature-preview-text {
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  color: #c2c2c7;
  margin: 10px 0 20px 0;
  width: 470px;
  height: auto;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0 0 0;
  height: 50px;
}

.carousel-arrow {
  color: #a4a1a1;
  font-size: 3rem;
  cursor: pointer;
  background: #161515;
  margin: 0 10px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.left-arrow {
  padding-right: 3px;
}

.right-arrow {
  padding-left: 3px;
}

.carousel-arrow:hover {
  color: white;
}

.main-content-section {
  display: none;
  flex-direction: row;
  justify-content: center;
  margin: 22px 0 0 0;
  align-items: center;
}

.main-content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 55px 0 0;
}

.main-content-sub-container {
  margin: 20px 0 0 0;
  padding: 0 60px 0 0;
}

.main-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content-title {
  font-family: "Akira Expanded", sans-serif;
  color: white;
  text-align: left;
  font-size: 30px;
  background-image: linear-gradient(
    10deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 10%,
    hsl(275deg 81% 47%) 20%,
    hsl(275deg 81% 47%) 31%,
    hsl(275deg 81% 47%) 42%,
    hsl(292deg 100% 39%) 53%,
    hsl(316deg 100% 42%) 65%,
    hsl(330deg 100% 47%) 77%,
    hsl(340deg 100% 49%) 89%,
    hsl(352deg 91% 51%) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.main-content-subtext {
  width: 440px;
  text-align: left;
  color: #c2c2c7;
}

.main-content-quote {
  width: 450px;
}

.subtitle {
  margin: 40px 0 30px 0;
  text-align: left;
  color: #efefef;
  font-family: "Akira Expanded", sans-serif;
  font-size: 15px;
  font-size: 30px;
  font-weight: 600;
}

.subtitle-sorting {
  text-align: center;
  color: #c2c2c7;
  font-size: 30px;
  font-weight: 600;
  width: 1000px;
  margin: 70px auto 30px auto;
}

.founder-quote {
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  font-family: "Akira Expanded", sans-serif;
  color: white;
  width: 820px;
  text-align: center;
  font-size: 15px;
  font-style: italic;
}

.custom-text-group {
  margin: 20px 0 70px 0;
}

.custom-format-text {
  text-align: center;
  margin: 10px 0 10px 0;
  color: #c2c2c7;
  font-size: 20px;
}

.new-way-text {
  font-weight: 600;
}

.statement-text {
  font-weight: 700;
  font-size: 30px;
}

.artist {
  color: #f85133;
  font-weight: 600;
  font-size: 28px;
}

.musicfans {
  color: #c43183;
  font-weight: 600;
  font-size: 28px;
}

.creators {
  color: #8c00d9;
  font-weight: 600;
  font-size: 28px;
}

.landing-icon {
  height: 100px;
  width: 100px;
  margin: 0 auto 10px auto;
}

.landing-icon-subtext {
  color: #808083;
}

.create-account-enter-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.create-account-btn {
  font-family: "Akira Expanded", sans-serif;

  color: #13bc05;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 13px;
  margin: 0 5px 0 650px;
  font-weight: 800;
}

.enter-arrow {
  font-size: 16px;
  color: #13bc05;
}

.create-account-btn-centerpage {
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 8px 15px 8px 15px;
  background-color: #119b05;
  font-weight: 600;
  cursor: pointer;
  height: 30px;
  margin: 15px auto 60px auto;
}

.register-btn {
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 8px 15px 8px 15px;
  background-color: #0aac15;
  font-weight: 700;
  cursor: pointer;
  width: auto;
  transition: all 0.3s ease;
}

.register-btn:hover {
  background-color: #078910;
  cursor: pointer;
}

.login-btn {
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 8px 15px 8px 15px;
  background-color: #8a01db;
  font-weight: 700;
  cursor: pointer;
  width: auto;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background-color: #7614af;
  cursor: pointer;
}

.create-account-text {
  font-family: "Akira Expanded", sans-serif;
  text-align: center;
  color: #8c00d9;
  font-weight: 700;
  font-size: 25px;
}

.create-account-text-help-text {
  font-size: 12px;
  color: #c2c2c7;
  text-align: center;
  font-style: italic;
  margin: 0;
  display: none;
}

.create-account-text-help-text-small {
  font-size: 10px;
  color: #808083;
  text-align: center;
  color: white;
  font-weight: 500;
  margin: 18px 0 30px 0;
  display: none;
}

.musicfans-waiting-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e90d3b;
  width: 370px;
  padding: 35px;
  margin: 0 20px 0 0;
}

.appstoredownload-image {
  height: 55px;
  margin: 10px 0 0 0;
}

.musicfans-join-title {
  font-family: "Akira Expanded", sans-serif;
  font-size: 20px;
  color: #e90d3b;
  margin: 0;
  text-align: center;
}

.artists-join-title {
  font-family: "Akira Expanded", sans-serif;
  font-size: 20px;
  color: white;
  margin: 0;
  text-align: center;
}

.musicfans-join-subtext,
.artists-join-subtext {
  color: white;
  font-size: 18px;
  font-family: "Akira Expanded", sans-serif;
}

.artists-signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c900fc;
  width: 370px;
  padding: 35px;
  justify-content: center;
  margin: 0 0 0 20px;
}

.artists-signup-title {
  font-family: "Akira Expanded", sans-serif;
  font-size: 20px;
  color: #8a00dc;
  margin: 0;
}

.artists-register-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  background-color: #10a403;
  border-radius: 4px;
  width: 200px;
  height: 31px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 14px 0 14px;
  color: white;
  border: none;
  text-decoration: none;
  text-align: center;
  margin: 20px 0 0 0;
}

.artists-register-link:hover {
  cursor: pointer;
  background-color: #13bc05;
}

.signup-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 60px 0 100px 0;
}

.signup-info {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.waitlist-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testSuccess {
  border: 1px solid green;
  color: green;
  margin: 20px auto 0 auto;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  width: 310px;
}

.signup-input-text {
  color: #ffffff;
  font-size: 20px;
}

.signup-input {
  font-family: "Montserrat";
  background-color: transparent;
  border: 1px solid #4b4b4b;
  font-size: 13px;
  color: #ffffff;
  font-weight: 300;
  border-radius: 4px;
  width: 210px;
  height: 31px;
  outline: none;
  margin: 0 5px 0 0;
}

.signup-input:focus {
  border: 1px solid #888787;
}

.signup-help-text {
  font-size: 18px;
  color: #930deb;
  text-align: center;
  margin: 0 auto 30px auto;
  font-weight: 700;
}

.join-header-text {
  color: white;
  font-size: 18px;
  font-family: "Akira Expanded", sans-serif;
  text-align: center;
  margin: 0 auto 10px auto;
}

.signup-submit-btn {
  font-family: "Montserrat";
  background-color: #303030;
  border-radius: 4px;
  width: 70px;
  height: 31px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 14px 0 14px;
  color: white;
  border: none;
}

.signup-submit-btn:hover {
  cursor: pointer;
  background-color: #393939;
}

.waitlist-email-success {
  border: 1px solid green;
  color: green;
  margin: 20px auto 0 auto;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 310px;
  transition: opacity 1s ease-out;
  opacity: 1;
}

.waitlist-email-success.fade-out {
  opacity: 0;
}

.waitlist-email-error {
  border: 1px solid red;
  margin: 20px auto 0 auto;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  color: red;
  font-size: 12px;
  font-weight: 600;
  width: 310px;
}

.bottom-page-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.landing-bottom-content-title {
  color: #c2c2c7;
  font-size: 40px;
  font-weight: 400;
}

.what-we-arent-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0 50px 0;
}

.why-join-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 70px 0;
}

.why-join-header {
  color: white;
  font-weight: 600;
  margin: 0 0 0 0;
  text-align: center;
}

.why-join-header-subtext {
  color: white;
  font-weight: 300;
  margin: 10px 0 0 0;
  font-style: italic;
}

.why-join-subtext {
  color: #c2c2c7;
  font-weight: 300;
  padding: 0;
  width: 275px;
  text-align: left;
}

.landing-icon-text-container {
  display: none;
}

.landing-card-container {
  border: 1px solid #707070;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: auto;
  margin: 0 20px 0 20px;
  padding: 10px;
}

.landing-bottom-section-title {
  color: #c2c2c7;
  font-size: 30px;
  font-weight: 600;
}

.how-it-works-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0 60px 0;
  justify-content: center;
}

.how-it-works-textbox-container {
  display: flex;
  flex-direction: column;
}

.how-it-works-header {
  font-family: "Akira Expanded", sans-serif;
  color: white;
  font-weight: 600;
  margin: 30px 0 0 0;
  text-align: center;
  font-size: 18px;
}

.how-it-works-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 0;
}

.how-it-works-subtext {
  color: #c2c2c7;
  font-weight: 300;
  text-align: center;
  font-weight: 300;
  width: 370px;
  margin: 20px auto 5px auto;
}

.zones-group-desktop {
  height: 360px;
  width: 360px;
  margin: 30px 0 0 0;
}

.zones-group-mobile {
  display: none;
}

.zone-example {
  height: 140px;
  width: 140px;
  margin: 10px 0 20px 0;
}

.discover-subtext {
  text-align: center;
  color: #c2c2c7;
  font-weight: 300;
  width: 350px;
  margin: 20px auto 0 auto;
}

.quote-credit {
  text-align: center;
  margin: 0;
  color: #c2c2c7;
  font-style: italic;
}

footer {
  background: #1c1c1c;
  height: auto;
  width: 100%;
  bottom: 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-size: 12px;
  padding: 25px 0 15px 0;
  align-items: center;
}

.footer-entire-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-text-spacing {
  padding: 3px 0 3px 0;
}

.contact-email-footer {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  display: flex;
}

.footer-links-group {
  display: flex;
  flex-direction: column;
  /*padding: 10px 40px 10px 0;*/
  padding: 0 60px 20px 60px;
}

h6 {
  margin: 0;
  color: #7d0ddd;
  font-size: 15px;
  padding: 0 0 8px 0;
}

.footer-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  padding: 0 0 7px 0;
  margin: 0;
}

.augio-footer-tranparent-g {
  height: 90px;
  width: 90px;
  position: absolute;
  left: 0;
  display: flex;
}

.clickable-link {
  padding: 0;
}

.clickable-link:hover,
.quick-link:hover {
  text-decoration: underline;
}

.footer-link:active {
  color: #ffffff;
}

.footer-link:visited {
  color: #ffffff;
}

.footer-icon-link:visited {
  color: #ffffff;
}

.footer-social-text {
  color: #ffffff;
  padding: 0 0 0 0;
}

.footer-icon-group {
  display: flex;
  flex-direction: column;
  /*margin: 2px 40px 15px 0;*/
}

.social-link-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-link-name {
  font-size: 10px;
  font-weight: 600;
  margin: 0 0 0 0;
}

.footer-icon {
  font-size: 15px;
  cursor: pointer;
  margin: 0 3px 0 3px;
  color: white;
}

.footer-icon:hover {
  color: #8a01db;
  cursor: pointer;
  transition: all 0.3s;
}

.augio-copyright {
  text-align: center;
  background: linear-gradient(
    66deg,
    rgba(137, 23, 217, 1) 38%,
    rgba(246, 81, 58, 1) 100%
  );
  background-clip: text;
  color: transparent;
  font-weight: 500;
}

.signup-validation {
  display: flex;
  align-items: center;
}

.signup-error-message {
  display: flex;
  flex-direction: column;
}

.signup-error-info {
  color: red;
  font-size: 12px;
  width: 250px;
  padding-left: 10px;
}

@media only screen and (max-width: 420px) {
  .landing-page-container {
    flex-direction: column;
    margin: 0 auto 0 auto;
    padding-bottom: 50px;
  }

  .create-account-enter-link {
    display: none;
  }

  .create-account-btn {
    display: none;
  }

  .augio-landing-image {
    width: 400px;
    height: 310px;
  }

  .custom-header-font {
    font-size: 19px;
  }

  .text-over-image-01 {
    margin: 0 0 10px 23px;
  }

  .text-over-image-02 {
    margin: 0 0 15px 65px;
  }

  .text-over-image-03 {
    margin: 0 0 15px 50px;
  }

  .text-over-image-04 {
    margin: 0 0 0 70px;
  }

  .text-over-image-05 {
    margin: 0 0 0 90px;
  }

  .intro-text {
    margin-top: 210px;
  }

  .introducing-quote-text {
    width: 360px;
  }

  .custom-font-heading {
    font-size: 25px;
  }

  .why-join-title-artists,
  .why-join-title-fans {
    font-size: 22px;
  }

  .artists-why-join,
  .fans-why-join {
    height: 190px;
  }

  .augio-statements-container {
    display: flex;
    flex-direction: column;
  }

  .statement-container {
    width: 330px;
    height: 370px;
    margin: 0 0 5px 0;
  }

  .gradient-header-text {
    font-size: 26px;
    width: 350px;
    text-align: center;
  }

  .ios-app-image {
    height: 485px;
  }

  .ios-feature-preview-text {
    width: 315px;
  }

  .mobile-chain-link {
    display: block;
  }

  .why-join-description {
    width: 330px;
    font-size: 14px;
    margin-bottom: 20px;
    margin: 0;
  }

  .differentiator-subtext {
    font-size: 8px;
    width: 178px;
  }

  .vertical-divider {
    margin: 30px 20px 0 20px;
    height: 200px;
  }

  .how-it-works-subtext {
    width: 350px;
  }

  .main-content-section {
    flex-direction: column;
    margin: 40px auto 0 auto;
  }

  .main-content-title {
    text-align: center;
    font-size: 23px;
  }

  .main-content-subtext {
    text-align: center;
    width: 400px;
  }

  .main-content-left {
    align-items: center;
    padding: 0 0 0 0;
  }
  .main-content-sub-container {
    padding: 0 0 0 0;
  }

  .main-content-right {
    align-items: center;
  }

  .zones-group-desktop {
    height: 260px;
    width: 260px;
  }
  .top-page-text {
    margin: 0 0 0 0;
  }

  .landing-large-text {
    font-size: 20px;
  }

  .signup-container {
    flex-direction: column;
    align-items: center;
  }

  .musicfans-waiting-list-container {
    margin: 0 0 30px 0;
    width: 340px;
  }

  .artists-signup-container {
    margin: 0 0 0 0;
    width: 340px;
  }

  .signup-help-text {
    font-size: 13px;
    width: 350px;
    margin-bottom: 20px;
  }

  .landing-logo {
    height: 50px;
    width: 225px;
    margin: 20px auto 20px auto;
  }

  .founder-quote {
    font-size: 11px;
    width: 350px;
  }

  .footer-links-group {
    padding: 0 20px 20px 20px;
  }

  .augio-footer-tranparent-g {
    display: none;
    margin: 0;
    padding: 0;
  }

  .why-join-section {
    flex-direction: column;
  }

  .artists-why-join,
  .fans-why-join {
    height: 220px;
    width: 100%;
  }

  .augio-benefits {
    flex-direction: column;
    align-items: center;
  }

  .artist-benefits {
    margin: 0 0 25px 0;
    width: 300px;
  }

  .fan-benefits {
    margin: 0;
    width: 300px;
  }

  .feature-content {
    flex-direction: column;
    margin-top: 0;
  }

  .features-section {
    margin-bottom: 90px;
  }

  .feature-image-screenshot-container {
    width: 340px;
  }

  .feature-image-screenshot {
    width: 280px;
  }

  .feature-title {
    font-size: 24px;
  }

  .feature-quote {
    font-size: 11px;
    width: 300px;
  }

  .feature-description {
    font-size: 16px;
    width: 320px;
  }

  .feature-text-group {
    padding: 0;
    margin: 25px 0 0 0;
  }
  .carousel-controls {
    margin-top: 10px;
  }
}
