.top100-augio-logo {
    height: 50;
    width: 150px;
    margin: 0 0 5px 0;
}

.top100-randomize-btn {
    font-family: "Montserrat";
    font-weight: 600;
    color: white;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background: #3f007c;
    border: none;
    margin: 20px 0 15px 0;
}

.top100-randomize-btn:hover {
    cursor: pointer;
}

.info-eyeball {
    font-size: 15px;
    margin: 0 0 0 5px;
    color: #C2C2C7;
    position: relative;
    cursor: pointer;
}

.info-container {
    position: relative; 
}

.info-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.tooltip {
    position: absolute;
    bottom: 120%; 
    left: 70%; 
    transform: translateX(-20%); 
    background-color: #ffffff; 
    color: #000; 
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    z-index: 10;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); 
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #f0f0f0 transparent transparent transparent; 
}
