.audio-upload-container {
  font-family: "Montserrat";

  height: auto;
  margin: 0 0 20px 0;
}

.filter-btn-container {
  margin: 5px 0 20px 0;
}

.individual-audio-upload-content {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px;
  background: white;
  margin: 0 0 10px 0;
  border-radius: 8px;
  width: 900px;
}

.individual-audio-upload-content.editing {
  width: 980px;
}

.individual-audio-upload-content:hover {
  box-shadow: 0 5px 11px 0 rgba(206, 206, 207, 0.62);
}

.audio-upload-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.audio-upload-spacing {
  margin: 0 10px 0 0;
}

.audio-upload-name {
  font-weight: 600;
}

.audio-upload-time {
  font-weight: 400;
  color: #808083;
}

.audio-upload-streams {
  font-weight: 400;
  color: #808083;
}

.audio-upload-likes {
  font-weight: 400;
  color: #808083;
}

.audio-upload-edit-btn {
  font-family: "Montserrat";
  font-weight: 600;
  border: none;
  border-radius: 3px;
  margin: 0 0 0 auto;
  padding: 5px;
}

.audio-upload-edit-btn:hover {
  cursor: pointer;
  background: #dadada;
}

.audio-upload-delete-btn {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  border: none;
  border-radius: 3px;
  margin: 0 0 0 7px;
  padding: 5px;
  color: red;
}

.audio-upload-delete-btn:hover {
  cursor: pointer;
  background: #dadada;
}

.audio-upload-icon {
  font-size: 18px;
  margin: 0 10px -3px 0;
}

.au-play {
  color: green;
}

.au-like {
  color: red;
}

.audio-upload-load-more-btn {
  width: 900px;
  background: #ebebee;
  color: black;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 12px;
  margin: 0 auto 0 auto;
  border: none;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
}

.audio-upload-load-more-btn:hover {
  background: #d4d4d7;
}

.audio-upload-save-changes-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 60px;
  height: 20px;
  background: #0091ff;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
}

.audio-upload-save-changes-btn:hover {
  cursor: pointer;
  background: #0175ce;
}

.audio-upload-cancel-changes-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 60px;
  height: 20px;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  background: #bababe;
  margin: 0 0 0 10px;
}

.audio-upload-cancel-changes-btn:hover {
  cursor: pointer;
  background: #97979b;
}

.audio-upload-form-input-group {
  display: flex;
  flex-direction: row;
  margin: 15px 0 5px 0;
}

.audio-upload-label-input-group {
  display: flex;
  flex-direction: column;
}

.audio-upload-edit-label {
  font-weight: 600;
  font-size: 11px;
}

.audio-upload-edit-input {
  margin: 7px 10px 0 0;
  background-color: rgb(252, 252, 253);
  border: 1px solid rgb(232, 234, 237);
  border-radius: 4px;
  color: #3d3d40;
  width: 150px;
  height: 25px;
  outline: none;
  font-size: 12px;
  font-family: "Noto Sans", sans-serif;
}

.delete-text {
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.delete-yes-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 60px;
  height: 20px;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  background: red;
}

.delete-no-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 60px;
  height: 20px;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  background: #bababe;
  margin: 0 0 0 10px;
}

.delete-yes-btn,
.delete-no-btn:hover {
  cursor: pointer;
}

.mobile-message {
  display: none;
}

@media only screen and (max-width: 420px) {
  .individual-audio-upload-content {
    width: 365px;
  }

  .audio-upload-edit-btn,
  .audio-upload-delete-btn {
    display: none;
  }

  .audio-upload-load-more-btn {
    width: 365px;
  }

  .mobile-message {
    display: block;
    font-size: 11px;
    font-weight: 600;
    margin: 0 0 20px 0;
    background: #d6f5d6;
    color: #085c08;
    padding: 6px;
    border-radius: 3px;
    width: 345px;
  }
}
