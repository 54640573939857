.header-title {
  font-family: "Montserrat";
  font-size: 27px;
  font-weight: 700;
  padding: 0 0 10px 0;
  margin: 0;
}

.discover-filter-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 10px 0;
}

.discover-divider {
  margin: 5px 0 10px 0;
  width: 1000px;
  color: #979797;
  opacity: 0.5;
}

.discover-subtext {
  font-family: "Montserrat";
  font-weight: 600;
  color: black;
  margin: 0;
  width: 110px;
  padding: 0 0 0 0;
  font-size: 15px;
}

.subheader-title {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 10px 0;
}

.subheader-subtext {
  font-family: "Montserrat";
  font-size: 11px;
  font-weight: 500;
  margin: 3px 0 10px 3px;
  color: #808083;
}

.discover-subtitle {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.state-filters {
  width: 1000px;
  margin: 10px 0 10px 0;
}

.state-filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 2px 6px 2px 6px;
  margin: 3px 5px 3px 0;
  font-size: 10px;
  transition: all 0.3s ease;
  color: black;
}

.state-filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.state-filter-btn-selected {
  background: #dddde2;
}

.chart-region-filter-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0 5px 0;
}

.filter-row {
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px 0;
  width: 1100px;
  flex-wrap: wrap;
}

.disover-filter-help-text {
  font-family: "Montserrat";
  font-size: 13px;
  font-style: italic;
  color: #979797;
  margin: 0;
}

.chart-region-filter-item {
  margin: 0 30px 10px 0;
}

.chart-region-filter-item:hover {
  cursor: pointer;
}

.chart-region-filter-image {
  background: black;
  width: 140px;
  height: 140px;
  border-radius: 5px;
}

.chart-filter-state-cover-image,
.region-filter-region-cover-image {
  width: 140px;
  height: 140px;
}

.chart-region-filter-name {
  font-family: "Montserrat";
  font-weight: 600;
  color: grey;
  font-size: 12px;
  margin: 3px 0 0 0;
}

@media only screen and (max-width: 420px) {
  .state-filters {
    width: 365px;
  }

  .discover-divider {
    width: 360px;
  }

  .filter-row {
    overflow-x: auto;
    width: 365px;
    flex-wrap: nowrap;
  }
}
