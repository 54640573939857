.toolbar-container {
  background: #1a1a1a;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding: 13px;
  position: fixed;
  z-index: 10;
}

.toolbar-logo {
  height: 35px;
  width: 34px;
  margin: 0 20px 0 5px;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  display: inline-block;
  background-color: #2c2c2c;
  color: white;
  margin: 0 0 0 150px;
  border-radius: 3px;
}

.search-input {
  font-family: "Montserrat";
  background-color: #2c2c2c;
  padding: 8px 50px 8px 10px;
  outline: none;
  border: none;
  color: white;
  border-radius: 3px;
  width: 300px;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: white;
  outline: none;
}

.toolbar-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 auto;
  position: relative;
}

.promote-music-btn {
  font-family: "Montserrat";
  font-weight: 600;
  background: #229004;
  border: none;
  padding: 7px 15px 7px 15px;
  color: white;
  border-radius: 4px;
  margin: 0 40px 0 0;
  cursor: pointer;
  display: none;
}

.upload-btn {
  font-family: "Montserrat";
  font-weight: 600;
  background: #3f007c;
  border: none;
  padding: 7px 15px 7px 15px;
  color: white;
  border-radius: 4px;
  margin: 0 60px 0 0;
}

.upload-btn:hover {
  cursor: pointer;
}

.account-settings-btn {
  font-family: "Montserrat";
  font-weight: 600;
  background: #cdcdcd;
  border: none;
  padding: 7px 10px 7px 10px;
  color: black;
  border-radius: 4px;
  margin: 0 20px 0 0;
}

.account-settings-btn:hover {
  cursor: pointer;
}

.account-dropdown {
  font-family: "Montserrat";
  font-weight: 600;
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.account-dropdown div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.account-dropdown div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.clear-button {
  color: white;
  font-size: 13px;
}

.notification-group {
  position: relative;
  margin: 0 50px 0 0;
}

.notification-icon {
  color: white;
  font-size: 23px;
  margin: 0;
}

.notification-icon:hover {
  cursor: pointer;
}

.notification-dropdown {
  font-family: "Montserrat";
  font-weight: 600;
  background-color: #fff;
  position: absolute;
  top: calc(100% + 30px);
  left: 50%;
  transform: translateX(-100%);
  width: 330px;
  height: 900px;
  max-height: 900px;
  -webkit-box-shadow: -1px 3px 20px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -1px 3px 20px 8px rgba(0, 0, 0, 0.12);
  box-shadow: -1px 3px 20px 8px rgba(0, 0, 0, 0.106);
  z-index: 1000;
  margin: 0 0 0 0;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.notification-red-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.load-more-notifications-btn {
  width: 100%;
  border: none;
  background: #d8d8dd;
  height: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  cursor: pointer;
}

.alert-icon {
  font-size: 25px;
}

.no-notification-message {
  font-size: 14px;
  text-align: center;
  color: #c2c2c7;
  margin: 50px 0 0 0;
}

.hamburger-menu-icon {
  display: none;
}

.load-more-notifications-btn {
  width: 100%;
  border: none;
  height: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  background: #ebebee;
}

@media only screen and (max-width: 420px) {
  .toolbar-btn-container {
    margin-left: 160px;
  }

  .toolbar-logo {
    width: 33px;
    margin-left: 0;
  }

  .upload-btn {
    display: none;
  }

  .input-wrapper {
    margin-left: 10px;
    display: none;
  }

  .search-input {
    width: 160px;
  }

  .account-settings-btn {
    margin: 0;
    padding: 5px 10px 5px 10px;
  }

  .account-dropdown {
    top: 40px;
    right: 0;
  }

  .notification-group {
    margin-right: 30px;
  }

  .notification-dropdown {
    transform: translateX(-65%);
    width: 300px;
  }

  .hamburger-menu-icon {
    display: block;
    font-size: 30px;
    color: white;
    margin: 0;
  }
}
