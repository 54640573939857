.feed-container {
  margin: 10px 0 20px 0;
}

.feed-filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s ease;
  color: black;
}

.feed-filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.feed-filter-btn.selected {
  background-color: #dddde2;
}

.feed-tracks-container {
  width: 920px;
  overflow-y: auto;
  box-sizing: border-box;
}

.feed-tracks-container.empty {
  height: 0;
  overflow: hidden;
}

.feed-tracks-container.not-empty {
  height: 500px;
}

.feed-tracks-container::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.feed-tracks-container::-webkit-scrollbar-thumb {
  background: #dddde2;
  border-radius: 10px;
  height: 70px;
}

@media only screen and (max-width: 420px) {

  .feed-container {
    width: 370px;
  }

  .feed-tracks-container.not-empty {
    width: 370px;
  }
}
